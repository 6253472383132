import posthog from 'posthog-js';
import { AccountRole, AccountType, UserRole } from '../data/enums';
import { checkIsDemo } from './demoMode';
var _config = config, featureFlags = _config.featureFlags;
export var BooleanFlaggedFeatures = Object.freeze({
    QuizrrProductMetrics: 'quizrrProductMetrics',
    PaymentIntegration: 'paymentIntegration',
    AccountSelection: 'accountSelection',
    NewFacilitiesTrainingReport: 'newFacilitiesTrainingReport',
    AcceptTermsAndPrivacyPolicy: 'acceptTermsAndPrivacyPolicy'
});
var flagslist = ['quizrrProductMetrics', 'trainingProgressReportDownload', 'paymentIntegration'];
export var flags = {};
posthog.onFeatureFlags(function () {
    flagslist.forEach(function (flag) {
        flags[flag] = posthog.isFeatureEnabled(flag);
    });
});
var getFlagState = function getFlagState(feature) {
    var allowOnDev = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return allowOnDev && process.env.NODE_ENV !== 'production' || featureFlags[feature];
};
export var isAdmin = function isAdmin(role) {
    return (role === 'quizrr_employee' || role === 'admin') && !checkIsDemo();
};
export var isAccountOwnerOrAdmin = function isAccountOwnerOrAdmin(accounts) {
    return accounts.filter(function (account) {
        return account.role === 'admin' || account.role === 'owner';
    }).length > 0;
};
export var isAccountOwner = function isAccountOwner(accounts) {
    return accounts.filter(function (account) {
        return account.role === 'owner';
    }).length > 0;
};
export var isTrainingAccountOwner = function isTrainingAccountOwner(accounts) {
    var userOwnedAccounts = accounts.filter(function (account) {
        return account.role === AccountRole.OWNER;
    });
    return userOwnedAccounts.some(function (account) {
        return account.accessibleQRCodes.length > 0;
    });
};
export var isOrganizationAccountUser = function isOrganizationAccountUser(accounts) {
    return accounts.filter(function (account) {
        return (account.role === AccountRole.USER || account.role === AccountRole.ADMIN || account.role === AccountRole.OWNER) && account.accountType === AccountType.ORGANIZATION;
    }).length > 0;
};
export var isBrandAccountUser = function isBrandAccountUser(accounts) {
    return accounts.filter(function (account) {
        return account.role === AccountRole.USER && account.accountType === AccountType.BRAND;
    }).length > 0;
};
export var isBrandAccountOwner = function isBrandAccountOwner(accounts) {
    return accounts.filter(function (account) {
        return (account.role === AccountRole.OWNER || account.role === AccountRole.ADMIN) && account.accountType === AccountType.BRAND;
    }).length > 0;
};
export var isTrainingFacilityAccountOwner = function isTrainingFacilityAccountOwner(accounts) {
    return accounts.filter(function (account) {
        return account.role === AccountRole.OWNER && account.accountType === AccountType.TRAINING_FACILITY;
    }).length > 0;
};
export var isTrainingTeamAdmin = function isTrainingTeamAdmin(accounts) {
    var teamAdminAccounts = accounts.filter(function (account) {
        return account.role === AccountRole.TEAM_ADMIN;
    });
    return teamAdminAccounts.some(function (account) {
        return account.accessibleQRCodes.length > 0;
    });
};
export var isBrandLocalManager = function isBrandLocalManager(accounts) {
    return accounts.some(function (account) {
        return account.role === AccountRole.LOCAL_MANAGER && account.accountType === AccountType.BRAND;
    });
};
export var isBrandLocalTrainer = function isBrandLocalTrainer(accounts) {
    return accounts.some(function (account) {
        return account.role === AccountRole.LOCAL_TRAINER && account.accountType === AccountType.BRAND;
    });
};
export var canAccessDashboard = function canAccessDashboard(role) {
    return role !== 'account_player';
};
export var isPlayer = function isPlayer(_ref) {
    var userRole = _ref.userRole, accounts = _ref.accounts;
    return userRole === UserRole.ACCOUNT_PLAYER && accounts.some(function (account) {
        return account.role === AccountRole.PLAYER;
    });
};
export var canAccessTeamManagement = function canAccessTeamManagement(accounts) {
    return isAccountOwnerOrAdmin(accounts) || isTrainingTeamAdmin(accounts) || isTrainingAccountOwner(accounts) || isBrandLocalManager(accounts) || isBrandLocalTrainer(accounts);
};
export var isSupplierAccount = function isSupplierAccount(accounts) {
    return accounts.filter(function (account) {
        return account.accountFacilityType === 'supplier';
    }).length > 0;
};
export var canAccessResourcesManagement = function canAccessResourcesManagement(features) {
    return features === null || features === void 0 ? void 0 : features.includes('manage-resources');
};
var featureEnabled = function featureEnabled(key) {
    var role = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return isAdmin(role) || getFlagState(key);
};
export default featureEnabled;
