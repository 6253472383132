import _typeof from "@babel/runtime/helpers/typeof";
export var sortAlphabetically = function sortAlphabetically(options) {
    return options.sort(function (element, nextElement) {
        var _getValue$localeCompa;
        var getValue = function getValue(option) {
            var _option$text, _option$label;
            return _typeof(option) === 'object' ? ((_option$text = option.text) === null || _option$text === void 0 ? void 0 : _option$text.trim()) || ((_option$label = option.label) === null || _option$label === void 0 ? void 0 : _option$label.trim()) || '' : option.trim();
        };
        return (_getValue$localeCompa = getValue(element).localeCompare(getValue(nextElement))) !== null && _getValue$localeCompa !== void 0 ? _getValue$localeCompa : 0;
    });
};
