import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _regeneratorRuntime from "@babel/runtime/regenerator";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import axios from 'axios';
import { TRAINING_QUESTION_FILE } from '../data/queries';
import { formatQuestions, formatQuestionsBaseline, formatQuestionsOfNewBaseline } from './formatQuestions';
import captureException from './exceptions';
export var storeQuestions = function () {
    var _ref2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(_ref) {
        var market, trainingSeries, questionsVersion, language, hasKey, apolloClient, questionsLanguage, url, questions, _ref3, data, defaultUrl, _yield$axios, _data, trainingQuestionFile, sessionStorageKey, allQuestions, formattedQuestions;
        return _regeneratorRuntime.wrap(function _callee$(_context) {
            while (1)
                switch (_context.prev = _context.next) {
                    case 0:
                        market = _ref.market, trainingSeries = _ref.trainingSeries, questionsVersion = _ref.questionsVersion, language = _ref.language, hasKey = _ref.hasKey, apolloClient = _ref.apolloClient;
                        questionsLanguage = language;
                        url = "/static/questions/".concat(market, "/").concat(trainingSeries, "/questions.").concat(language, ".").concat(questionsVersion, ".json");
                        _context.prev = 3;
                        if (!hasKey) {
                            _context.next = 10;
                            break;
                        }
                        _context.next = 7;
                        return apolloClient.query({
                            query: TRAINING_QUESTION_FILE,
                            variables: {
                                filter: {
                                    trainingSeries: trainingSeries,
                                    market: market,
                                    version: questionsVersion
                                }
                            }
                        });
                    case 7:
                        _context.t0 = _context.sent;
                        _context.next = 13;
                        break;
                    case 10:
                        _context.next = 12;
                        return axios({
                            method: 'get',
                            url: url,
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    case 12:
                        _context.t0 = _context.sent;
                    case 13:
                        _ref3 = _context.t0;
                        data = _ref3.data;
                        questions = data;
                        _context.next = 30;
                        break;
                    case 18:
                        _context.prev = 18;
                        _context.t1 = _context["catch"](3);
                        if (!(_context.t1.response.status !== 404)) {
                            _context.next = 23;
                            break;
                        }
                        captureException(_context.t1);
                        return _context.abrupt("return");
                    case 23:
                        questionsLanguage = 'en';
                        defaultUrl = "/static/questions/".concat(market, "/").concat(trainingSeries, "/questions.en.").concat(questionsVersion, ".json");
                        _context.next = 27;
                        return axios({
                            method: 'get',
                            url: defaultUrl,
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    case 27:
                        _yield$axios = _context.sent;
                        _data = _yield$axios.data;
                        questions = _data;
                    case 30:
                        try {
                            trainingQuestionFile = questions.appTraining ? questions.appTraining : questions;
                            if (hasKey) {
                                questionsLanguage = trainingQuestionFile.app.locales.includes(language) ? language : 'en';
                            }
                            sessionStorageKey = hasKey ? "".concat(market, "-").concat(trainingSeries, "-").concat(questionsVersion, "-").concat(questionsLanguage, "-hasKey") : "".concat(market, "-").concat(trainingSeries, "-").concat(questionsVersion, "-").concat(questionsLanguage);
                            allQuestions = hasKey ? trainingQuestionFile.questions : Object.keys(trainingQuestionFile.categories).flatMap(function (learningModuleId) {
                                var learningModule = trainingQuestionFile.categories[learningModuleId];
                                if (!Object.prototype.hasOwnProperty.call(learningModule, 'level')) {
                                    return null;
                                }
                                var levels = Object.keys(learningModule === null || learningModule === void 0 ? void 0 : learningModule.level);
                                return levels.flatMap(function (level) {
                                    return learningModule.level[level].flatMap(function (_ref4) {
                                        var _ref4$media = _ref4.media, media = _ref4$media === void 0 ? {} : _ref4$media;
                                        return media.questions.map(function (q) {
                                            return _objectSpread(_objectSpread({}, q), {}, {
                                                learningModule: learningModuleId,
                                                level: level
                                            });
                                        });
                                    });
                                });
                            }).filter(function (v) {
                                return v;
                            });
                            formattedQuestions = formatQuestions({
                                allQuestions: allQuestions,
                                questionsLanguage: questionsLanguage,
                                hasKey: hasKey
                            });
                            sessionStorage.setItem(sessionStorageKey, JSON.stringify(formattedQuestions));
                        }
                        catch (err) {
                            captureException(err);
                        }
                    case 31:
                    case "end":
                        return _context.stop();
                }
        }, _callee, null, [[3, 18]]);
    }));
    return function storeQuestions(_x) {
        return _ref2.apply(this, arguments);
    };
}();
export var storeQuestionsBaseline = function () {
    var _ref6 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee2(_ref5) {
        var market, trainingSeries, questionsVersion, language, _yield$axios2, data, allQuestions, formattedQuestions;
        return _regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1)
                switch (_context2.prev = _context2.next) {
                    case 0:
                        market = _ref5.market, trainingSeries = _ref5.trainingSeries, questionsVersion = _ref5.questionsVersion, language = _ref5.language;
                        _context2.next = 3;
                        return axios({
                            method: 'get',
                            url: "/static/baselineQuestions/".concat(market, "/").concat(trainingSeries, "/").concat(questionsVersion, "/").concat(language, "/questions.json"),
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    case 3:
                        _yield$axios2 = _context2.sent;
                        data = _yield$axios2.data;
                        allQuestions = Object.keys(data.categories).flatMap(function (categoryId) {
                            var category = data.categories[categoryId];
                            var levels = Object.keys(category.level);
                            return levels.flatMap(function (level) {
                                return category.level[level].flatMap(function (_ref7) {
                                    var _ref7$media = _ref7.media, media = _ref7$media === void 0 ? {} : _ref7$media;
                                    return media.questions.map(function (q) {
                                        return _objectSpread(_objectSpread({}, q), {}, {
                                            category: categoryId,
                                            level: level
                                        });
                                    });
                                });
                            });
                        });
                        formattedQuestions = formatQuestionsBaseline({
                            allQuestions: allQuestions,
                            questionsLanguage: language
                        });
                        sessionStorage.setItem("baseline-".concat(market, "-").concat(trainingSeries, "-").concat(questionsVersion, "-").concat(language), JSON.stringify(formattedQuestions));
                        return _context2.abrupt("return", formattedQuestions);
                    case 9:
                    case "end":
                        return _context2.stop();
                }
        }, _callee2);
    }));
    return function storeQuestionsBaseline(_x2) {
        return _ref6.apply(this, arguments);
    };
}();
var dontKnowAnswer = {
    text: {
        bd: 'I_dont_know_bd',
        en: "I don't know",
        hi: 'I_dont_know_hi',
        "in": 'I_dont_know_in',
        km: 'I_dont_know_km',
        my: 'I_dont_know_my',
        ne: 'I_dont_know_ne',
        th: 'I_dont_know_th',
        tw: 'I_dont_know_tw',
        vn: 'Không có ý kiến'
    }
};
export var storeQuestionsOfNewBaseline = function () {
    var _ref9 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee3(_ref8) {
        var market, trainingSeries, questionsVersion, language, apolloClient, _yield$apolloClient$q, data, trainingQuestionFile, questionsLanguage, questions, learningModules, baselineLocations, baselineQuestionsKeys, formattedQuestions;
        return _regeneratorRuntime.wrap(function _callee3$(_context3) {
            while (1)
                switch (_context3.prev = _context3.next) {
                    case 0:
                        market = _ref8.market, trainingSeries = _ref8.trainingSeries, questionsVersion = _ref8.questionsVersion, language = _ref8.language, apolloClient = _ref8.apolloClient;
                        _context3.next = 3;
                        return apolloClient.query({
                            query: TRAINING_QUESTION_FILE,
                            variables: {
                                filter: {
                                    trainingSeries: trainingSeries,
                                    market: market,
                                    version: questionsVersion
                                }
                            }
                        });
                    case 3:
                        _yield$apolloClient$q = _context3.sent;
                        data = _yield$apolloClient$q.data;
                        trainingQuestionFile = JSON.parse(JSON.stringify(_objectSpread({}, data.appTraining)));
                        questionsLanguage = trainingQuestionFile.app.locales.includes(language) ? language : 'en';
                        questions = trainingQuestionFile.questions, learningModules = trainingQuestionFile.learning_modules;
                        Object.values(learningModules).forEach(function (learningModule) {
                            learningModule.baseline_locations.forEach(function (_ref10) {
                                var key = _ref10.key;
                                questions[key].answers.i_dont_know = dontKnowAnswer;
                            });
                        });
                        trainingQuestionFile.learning_modules = learningModules;
                        baselineLocations = Object.values(trainingQuestionFile.learning_modules).reduce(function (baselineLocationsAcc, learningModule) {
                            return learningModule.baseline_locations ? baselineLocationsAcc.concat(learningModule.baseline_locations) : baselineLocationsAcc;
                        }, []);
                        baselineQuestionsKeys = baselineLocations && Object.values(baselineLocations).map(function (_ref11) {
                            var key = _ref11.key;
                            return key;
                        }) || [];
                        formattedQuestions = formatQuestionsOfNewBaseline({
                            questionKeys: baselineQuestionsKeys,
                            questions: trainingQuestionFile.questions,
                            questionsLanguage: questionsLanguage
                        });
                        sessionStorage.setItem("baseline-".concat(market, "-").concat(trainingSeries, "-").concat(questionsVersion, "-").concat(questionsLanguage, "-hasKey"), JSON.stringify(formattedQuestions));
                        return _context3.abrupt("return", formattedQuestions);
                    case 15:
                    case "end":
                        return _context3.stop();
                }
        }, _callee3);
    }));
    return function storeQuestionsOfNewBaseline(_x3) {
        return _ref9.apply(this, arguments);
    };
}();
