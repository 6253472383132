export var JOYRIDE_RUN_PAGES = 'joyrideRunPages';
export var JOYRIDE_OVERVIEW = 'joyrideOverview';
export var JOYRIDE_FILTER_OVERVIEW = 'joyrideFilterOverview';
export var JOYRIDE_FACILITY = 'joyrideFacility';
export var JOYRIDE_FACILITY_GO = 'joyrideFacilityGo';
export var JOYRIDE_REPORTS = 'joyrideReports';
export var JOYRIDE_USER_PROFILE = 'UserProfile';
export var JOYRIDE_USER_LIST = 'joyrideUserAdministration';
export var JOYRIDE_RESOURCES = 'joyrideResources';
export var JOYRIDE_ACTIVE_TRAINING_PLANS = 'joyrideActiveTrainingPlans';
export var JOYRIDE_COMPLETED_TRAINING_PLANS = 'joyrideCompletedTrainingPlans';
export var JOYRIDE_FACILITY_ACTIVE_TRAINING_PLANS_DETAIL = 'joyrideFacilityActiveTrainingplanDetail';
export var JOYRIDE_FACILITY_COMPLETED_TRAINING_PLANS_DETAIL = 'joyrideFacilityCompletedTrainingplanDetail';
export var JOYRIDE_FACILITIES_TRAINING_PERFORMANCE = 'joyrideFacilitiesTrainingPerformance';
export var JOYRIDE_FACILITY_TRAINING_PERFORMANCE_DETAILS = 'joyrideFacilityTrainingPerformanceDetails';
export var JOYRIDE_PERFORMANCE_BY_QUESTION = 'joyridePerformanceByQuestion';
export var JOYRIDE_TEAM_MANAGEMENT = 'joyrideTeamManagement';
export var JOYRIDE_YOUR_TRAINING_OUTCOME = 'joyrideYourTrainingOutcome';
