import { createGlobalStyle } from 'styled-components';
var GlobalStyleScrollbar = createGlobalStyle(["::-webkit-scrollbar{width:5px;}::-webkit-scrollbar-track{border-radius:", ";box-shadow:inset 0 0 5px ", ";}::-webkit-scrollbar-thumb{background:", ";border-radius:", ";}"], function (_ref) {
    var theme = _ref.theme;
    return theme.spacing.baseDown3;
}, function (_ref2) {
    var theme = _ref2.theme;
    return theme.colors.gray500;
}, function (_ref3) {
    var theme = _ref3.theme;
    return theme.colors.gray500;
}, function (_ref4) {
    var theme = _ref4.theme;
    return theme.spacing.baseDown3;
});
export default GlobalStyleScrollbar;
