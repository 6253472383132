import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _typeof from "@babel/runtime/helpers/typeof";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import { removeNullFields } from '../../utils/object';
import { sortAlphabetically } from '../../utils/sort';
import { Filter as _jsdoc_Filter, FilterOption as _jsdoc_FilterOption, FilterOptions as _jsdoc_FilterOptions, FilterValues as _jsdoc_FilterValues, DynamicFiltersMergeOptions as _jsdoc_DynamicFiltersMergeOptions, FilterOptionValues as _jsdoc_FilterOptionValues } from './types';
export var NUMBER_RANGE_PATTERN = /^(\d+)\.\.(\d+)$/;
export function parseOptions(options, prerequisiteValue) {
    if (!options) {
        return [];
    }
    function parseNumberRange(range) {
        var _range$match = range.match(NUMBER_RANGE_PATTERN), _range$match2 = _slicedToArray(_range$match, 3), _ = _range$match2[0], minExp = _range$match2[1], maxExp = _range$match2[2];
        var min = parseInt(minExp, 10);
        var max = parseInt(maxExp, 10);
        return Array.from({
            length: max - min + 1
        }, function (_, index) {
            return index + min;
        }).map(function (number) {
            return {
                text: number,
                value: number
            };
        });
    }
    if (Array.isArray(options)) {
        return options.flatMap(function (option) {
            if (typeof option === 'string' && NUMBER_RANGE_PATTERN.test(option)) {
                return parseNumberRange(option);
            }
            if (_typeof(option) === 'object') {
                return [option];
            }
            return [{
                    text: option,
                    value: option
                }];
        });
    }
    if (_typeof(options) === 'object' && options[prerequisiteValue] !== undefined) {
        return parseOptions(options[prerequisiteValue], prerequisiteValue);
    }
    return [];
}
export function mergeOptions() {
    for (var _len = arguments.length, options = new Array(_len), _key = 0; _key < _len; _key++) {
        options[_key] = arguments[_key];
    }
    var parsedOptions = options.flatMap(function (item) {
        return parseOptions(item);
    });
    var uniqueOptions = parsedOptions.filter(function (value, index, self) {
        return index === self.findIndex(function (o) {
            return typeof value.value === 'string' ? o.value.toLowerCase() === value.value.toLowerCase() : o.value === value.value;
        });
    });
    return uniqueOptions;
}
export function isMultiValueFilter(filter) {
    var displayType = filter.displayType;
    return displayType === 'multiTag' || displayType === 'multiSelectDropdown';
}
export var DynamicFilters = function () {
    function DynamicFilters(filters) {
        _classCallCheck(this, DynamicFilters);
        this._filters = filters.map(function (filter) {
            return _objectSpread(_objectSpread({}, filter), {}, {
                options: filter.options || []
            });
        });
    }
    return _createClass(DynamicFilters, [{
            key: "state",
            value: function state() {
                return this._filters.map(function (filter) {
                    return Object.freeze(_objectSpread(_objectSpread({}, filter), {}, {
                        __typename: 'DynamicFilter'
                    }));
                });
            }
        }, {
            key: "defaultValues",
            value: function defaultValues() {
                return this._filters.reduce(function (result, filter) {
                    return _objectSpread(_objectSpread({}, result), {}, _defineProperty({}, filter.name, filter.defaultValue !== null && filter.defaultValue !== undefined ? filter.defaultValue : isMultiValueFilter(filter) ? ['all'] : null));
                }, {});
            }
        }, {
            key: "merge",
            value: function merge(filters) {
                var mergeSettings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DynamicFilters.defaultMergeOptions();
                var mergedFilters = this._filters;
                filters.forEach(function (filter) {
                    var index = mergedFilters.findIndex(function (f) {
                        return f.name === filter.name;
                    });
                    if (index > -1) {
                        mergedFilters[index] = _objectSpread(_objectSpread(_objectSpread({}, mergedFilters[index]), removeNullFields(filter)), {}, {
                            options: mergedFilters[index].options
                        });
                        switch (mergeSettings.filterOptionsMergeStrategy) {
                            case 'combine':
                                mergedFilters[index].options = mergeOptions(mergedFilters[index].options, filter.options);
                                break;
                            case 'overwrite':
                                mergedFilters[index].options = filter.options;
                                break;
                            default:
                                break;
                        }
                    }
                    else {
                        mergedFilters.push(filter);
                    }
                });
                return new DynamicFilters(mergedFilters);
            }
        }, {
            key: "getDependentFilters",
            value: function getDependentFilters(prerequisiteFilterName) {
                return new DynamicFilters(this._filters.filter(function (f) {
                    return f.dependOn === prerequisiteFilterName;
                }));
            }
        }, {
            key: "addOptions",
            value: function addOptions(filterName, options) {
                var filters = this._filters;
                return new DynamicFilters(filters.map(function (filter) {
                    if (filter.name === filterName) {
                        return _objectSpread(_objectSpread({}, filter), {}, {
                            options: sortAlphabetically(mergeOptions(filter.options, options))
                        });
                    }
                    return filter;
                }));
            }
        }, {
            key: "overwriteOptions",
            value: function overwriteOptions(filterName, options) {
                var filters = this._filters;
                return new DynamicFilters(filters.map(function (filter) {
                    if (filter.name === filterName) {
                        return _objectSpread(_objectSpread({}, filter), {}, {
                            options: sortAlphabetically(mergeOptions(options))
                        });
                    }
                    return filter;
                }));
            }
        }, {
            key: "toggleFilterVisibility",
            value: function toggleFilterVisibility(filterName, isVisible) {
                var filters = this._filters;
                return new DynamicFilters(filters.map(function (filter) {
                    if (filter.name === filterName) {
                        return _objectSpread(_objectSpread({}, filter), {}, {
                            visible: isVisible
                        });
                    }
                    return filter;
                }));
            }
        }, {
            key: "removeFilter",
            value: function removeFilter(filterName) {
                var filters = this._filters;
                return new DynamicFilters(filters.reduce(function (acc, filter) {
                    if (filter.name === filterName) {
                        return acc;
                    }
                    return [].concat(_toConsumableArray(acc), [filter]);
                }, []));
            }
        }, {
            key: "getOptions",
            value: function getOptions(filterName) {
                var _this$_filters$find;
                return (_this$_filters$find = this._filters.find(function (_ref) {
                    var name = _ref.name;
                    return name === filterName;
                })) === null || _this$_filters$find === void 0 ? void 0 : _this$_filters$find.options;
            }
        }, {
            key: "isVisible",
            value: function isVisible(filterName) {
                var filters = this._filters;
                var index = filters.findIndex(function (f) {
                    return f.name === filterName;
                });
                if (index > -1) {
                    return filters[index].visible;
                }
                return false;
            }
        }], [{
            key: "defaultMergeOptions",
            value: function defaultMergeOptions() {
                return {
                    filterOptionsMergeStrategy: 'overwrite'
                };
            }
        }]);
}();
