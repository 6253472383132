import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _regeneratorRuntime from "@babel/runtime/regenerator";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o);
} return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); });
} return e; }
import { v4 as uuid } from 'uuid';
import isEmpty from 'lodash.isempty';
import { put } from '../api';
import captureException from '../exceptions';
var InternalEventTracker = function () {
    function InternalEventTracker() {
        _classCallCheck(this, InternalEventTracker);
        _defineProperty(this, "_context", void 0);
        this._context = {};
    }
    return _createClass(InternalEventTracker, [{
            key: "captureEvent",
            value: function () {
                var _captureEvent = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(event) {
                    var _this$_context$user;
                    var _ref, id, accounts, eventKey, err;
                    return _regeneratorRuntime.wrap(function _callee$(_context) {
                        while (1)
                            switch (_context.prev = _context.next) {
                                case 0:
                                    if (!isEmpty(this._context)) {
                                        _context.next = 2;
                                        break;
                                    }
                                    return _context.abrupt("return");
                                case 2:
                                    _ref = (_this$_context$user = this._context.user) !== null && _this$_context$user !== void 0 ? _this$_context$user : {
                                        id: null,
                                        accounts: []
                                    }, id = _ref.id, accounts = _ref.accounts;
                                    eventKey = uuid();
                                    _context.prev = 4;
                                    _context.next = 7;
                                    return put({
                                        endpoint: "events/".concat(eventKey),
                                        payload: _objectSpread(_objectSpread(_objectSpread({}, this._context), event), {}, {
                                            eventKey: eventKey,
                                            timestamp: Date.now(),
                                            user: id,
                                            accounts: accounts,
                                            payload: _objectSpread(_objectSpread({}, event.payload), {}, {
                                                pathname: window.location.pathname
                                            })
                                        }),
                                        withAuth: false
                                    });
                                case 7:
                                    _context.next = 13;
                                    break;
                                case 9:
                                    _context.prev = 9;
                                    _context.t0 = _context["catch"](4);
                                    err = _context.t0;
                                    captureException(err);
                                case 13:
                                case "end":
                                    return _context.stop();
                            }
                    }, _callee, this, [[4, 9]]);
                }));
                function captureEvent(_x) {
                    return _captureEvent.apply(this, arguments);
                }
                return captureEvent;
            }()
        }, {
            key: "setContext",
            value: function setContext(context) {
                this._context = context;
            }
        }, {
            key: "updateContext",
            value: function updateContext(payload) {
                this._context = _objectSpread(_objectSpread({}, this._context), payload);
            }
        }]);
}();
export default new InternalEventTracker();
