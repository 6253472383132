import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
export default function captureException(error) {
    var extras = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    Sentry.captureException(error, function (scope) {
        scope.setExtras(extras);
        return scope;
    });
    posthog.captureException(error, extras);
}
